import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';

import { cartItemsArrayPropTypes, userPropTypes, pdfPropTypes } from 'helpers/propTypes';
import { isAbleToPurchase } from 'helpers/cart';
import { CART_CHECKOUT_DELIVERY_REQUESTED } from 'redux/constants/cart.constants';
import {
  checkout, submitCoupon, deleteCoupon, getProducts, clearCart,updateCart,
} from 'redux/actions/cart.actions';
import {
  cartItemIdsSelector, cartItemsSelector, cartSizeSelector, cartSubtotalSelector,
} from 'redux/selectors/cart.selector';
import { getShippingText } from 'components/forms/PaymentForm/constants';
import { setLocalStorage, removeLocalStorage, getLocalStorage } from 'helpers/storage';
import { getDiscount } from 'services/app.services';

import Checkout from 'views/Checkout';
import DeliveryForm from 'components/forms/DeliveryForm';

const DeliveryContainer = (props) => {
  const {
    cartItems, cartSize, checkoutAction, submitCouponAction, deliveryMethod, items, loading,
    minimumPurchase, subtotal, user, coupon, deleteCouponAction, resetCouponField, tarifas,
    envioGratis, getProductsAction, ids, clearCartAction,updateCartAction, envios,
  } = props;
  const [couponText, setCouponText] = useState(null);
  const [emailComplete, setEmailComplete] = useState(false);
  const [emailCoupon, setEmailCoupon] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [formulario, setFormulario] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [productsLoaded, setproductsLoaded] = useState(true);
  const [, setRefresh] = useState(Math.random());

  const envio = envios.find(envio => envio.id.toString() === deliveryMethod);
  const subTotEnv = deliveryMethod ? envio.monto_minimo !== 0 && subtotal >= envio.monto_minimo ? 0: envio.monto : 0;

  useEffect(() => {
    if (ids.length > 0 && !initialized) {
      getProductsAction(ids);
      setInitialized(true);
    }
    return () => getProductsAction([]);
    // eslint-disable-next-line
  }, [getProductsAction, JSON.stringify(ids)]);

  useEffect(() => {
    if (coupon) deleteCouponAction();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialized && productsLoaded) {
    // Filtrar el array del localStorage para eliminar los enteros que no están en el array de React
      const deleteItems = (getLocalStorage('craco-cart') || []).filter((item) => !items.map((prod) => prod.itemId).includes(item.itemId));
      // Actualizar el localStorage con el array filtrado
      deleteItems.map((item) => updateCartAction(item.itemId, 0, null, item.message));
      setproductsLoaded(false);
    }
    // eslint-disable-next-line
  }, [items]);

  const getDisounts = async () => {
    const { items: discounts } = await getDiscount();
    if (discounts.length > 0) {
      let newSubtotal = subtotal;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < discounts.length; i++) {
        if (subtotal > discounts[i].amount) {
          newSubtotal = subtotal - (subtotal * discounts[i].percentage) / 100;
          setLocalStorage('percentage', discounts[i].percentage);
        }
      }
      if (newSubtotal !== subtotal) {
        setLocalStorage('discount', newSubtotal);
      } else {
        removeLocalStorage('discount');
        removeLocalStorage('percentage');
      }
    } else {
      removeLocalStorage('discount');
      removeLocalStorage('percentage');
    }
    setRefresh(Math.random());
  };

  useEffect(() => {
    if (subtotal > 0) {
      getDisounts();
    }
    // eslint-disable-next-line
  }, [cartSize, subtotal]);

  const saveInfoUser = (formData) => {
    setLocalStorage('craco-info-delivery', formData);
  };

  const deleteInfoUser = () => {
    removeLocalStorage('craco-info-delivery');
  };

  const onConfirmDeliveryForm = () => {
    setModalOpen(false);
    console.log("cupon saltear valida: ",coupon?.is_minimum_amount);
    if(coupon?.is_minimum_amount === 0){
      const data = { ...formulario, coupon: coupon?.id };
      checkoutAction(
        CART_CHECKOUT_DELIVERY_REQUESTED,
        data,
        cartItems,
        user ? user.id : null,
      );
      deleteCouponAction();
    }else if (isAbleToPurchase(subtotal, minimumPurchase)) {
      const data = { ...formulario, coupon: coupon?.id };
      checkoutAction(
        CART_CHECKOUT_DELIVERY_REQUESTED,
        data,
        cartItems,
        user ? user.id : null,
      );
    }
  };

  const onSubmitDeliveryForm = (formData) => {
    setModalOpen(true);
    window.scrollTo(0, 0);
    if (formData.save_data)saveInfoUser(formData);
    else deleteInfoUser();
    setFormulario(formData);
  };

  const onDeleteCart = () => {
    clearCartAction();
    setModalOpen(false);
  };

  const onSubmitCoupon = () => {
    if (couponText && emailCoupon) {
      submitCouponAction(couponText, emailCoupon, getLocalStorage('percentage'));
    }
  };

  const onDeleteCoupon = () => {
    deleteCouponAction();
    resetCouponField('');
    setCouponText('');
  };
  const validationEmailCoupon = (email) => {
    if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailComplete(false);
      onDeleteCoupon();
    } else {
      setEmailComplete(true);
      setEmailCoupon(email);
    }
  };
  const renderDeliveryForm = () => (
    <DeliveryForm
      coupon={coupon}
      onSubmit={onSubmitDeliveryForm}
      user={user}
      envioGratis={envioGratis}
      onSubmitCoupon={coupon ? onDeleteCoupon : onSubmitCoupon}
      couponText={couponText}
      setCouponText={setCouponText}
      emailComplete={emailComplete}
      setEmailComplete={setEmailComplete}
      setEmailCoupon={setEmailCoupon}
      validationEmailCoupon={validationEmailCoupon}
      deliveryMethod={deliveryMethod}
      tarifas={tarifas}
      onConfirm={onConfirmDeliveryForm}
      isModalOpen={isModalOpen}
      setModalOpen={setModalOpen}
      onDeleteCart={onDeleteCart}
      minimumPurchase={minimumPurchase}
      envios={envios}
    />
  );

  return (
    <Checkout
      coupon={coupon}
      cartSize={cartSize}
      items={items}
      loading={loading}
      minimumPurchase={minimumPurchase}
      renderForm={renderDeliveryForm}
      shippingText={getShippingText(deliveryMethod, subtotal,envios)}
      subtotal={subtotal}
      subTotEnv={subTotEnv}
      url="/envio-a-domicilio"
      urlText="Envío a domicilio"
      isDelivery
    />
  );
};

const selector = formValueSelector('delivery');
const mapStateToProps = (state) => ({
  coupon: state.cart.coupon,
  cartItems: state.cart.items,
  cartSize: cartSizeSelector(state),
  deliveryMethod: selector(state, 'delivery_method'),
  ids: cartItemIdsSelector(state),
  items: cartItemsSelector(state),
  loading: state.cart.loading,
  minimumPurchase: state.app.minimumPurchase,
  envioGratis: state.app.envioGratis,
  subtotal: cartSubtotalSelector(state),
  user: state.user.data,
  tarifas: state.app.tarifas,
  envios: state.app.envios,
});

const mapDispatchToProps = (dispatch) => ({
  checkoutAction: bindActionCreators(checkout, dispatch),
  submitCouponAction: bindActionCreators(submitCoupon, dispatch),
  deleteCouponAction: bindActionCreators(deleteCoupon, dispatch),
  resetCouponField: (value) => dispatch(change('delivery', 'coupon', value)),
  getProductsAction: bindActionCreators(getProducts, dispatch),
  clearCartAction: bindActionCreators(clearCart, dispatch),
  updateCartAction: bindActionCreators(updateCart, dispatch),
});

DeliveryContainer.defaultProps = {
  deliveryMethod: null,
  user: null,
  coupon: null,
};

DeliveryContainer.propTypes = {
  cartItems: cartItemsArrayPropTypes.isRequired,
  cartSize: PropTypes.number.isRequired,
  clearCartAction: PropTypes.func.isRequired,
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
  }),
  getProductsAction: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  submitCouponAction: PropTypes.func.isRequired,
  deleteCouponAction: PropTypes.func.isRequired,
  resetCouponField: PropTypes.func.isRequired,
  checkoutAction: PropTypes.func.isRequired,
  deliveryMethod: PropTypes.string,
  items: cartItemsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  envioGratis: PropTypes.number.isRequired,
  subtotal: PropTypes.number.isRequired,
  user: userPropTypes,
  tarifas: pdfPropTypes.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryContainer);
